import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';

const TestingPopUp = ({ onClose }) => {
  const { t } = useTranslation();


  const FREE_LIMIT = 3;
  const REQUEST_LIMIT = 50;
  const MONTHLY_COST = 20; 
  const ANNUAL_COST = 240; 
  const DISCOUNT = 25; 
  const AVAILABLE_SEATS = 10;

  const monthlyDiscounted = MONTHLY_COST * (1 - DISCOUNT);
  const annualDiscounted = ANNUAL_COST * (1 - DISCOUNT);


  const handleTestingTermsClick = () => {
    window.location.href = '  https://docs.google.com/document/d/1wJxpxOAj0JAj_f7rStHJOQf9B4SWJr1fqQGHlv8-iAQ/edit?usp=sharing';
  };


  const handleTestingClick = () => {
    window.location.href = '/about-us?topic=Testing';
  };



  return (
    <div className="modal-overlay">
      <div className="modal-contents-subscryption">
        <div className="sub-comment" style={{ marginTop: '8vh' }}>
          <button className="close-button" onClick={onClose}>
            <Icon icon="material-symbols-light:close" id="close-icon-button" />
          </button>
          <button className="close-button" onClick={onClose} style={{left:'10px', top:'10px', right:'initial'}}>
            <Icon icon="material-symbols-light:arrow-back" id="close-icon-button" />
          </button>
        </div>
        <div className="sub-comment">
          <strong>{t('Testing:')}</strong>{' '}
          {t('testing_plan_message', { AVAILABLE_SEATS })}{' '}
          <a href="#" onClick={handleTestingTermsClick}>
            {t('testing terms here')}
          </a>.
        </div>
        <div className="sybscryption-button-choose">
          <button className="subscryption-button" onClick={handleTestingClick}>
            {t('Testing')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestingPopUp;
