import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from 'react-leaflet';
import { Icon } from 'leaflet';
import { useLeafletContext } from '@react-leaflet/core';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import { useMap, useMapEvents } from 'react-leaflet';
import '../styles/Map.css';
import '../styles/Leaflet.css';
import 'leaflet/dist/leaflet.css';
import { logger } from '../logger';
import GeoJSONMarkers from './GeoJSONMarkers';
import { useTranslation } from 'react-i18next';
import { loadDataFetch, saveDataToApi, useAuthFetch, ReportFetch, AddressByLatLng } from './api.jsx';
import { useCookies } from 'react-cookie';
import api from '../config';


function CustomControl({ toggleRoleSVisible, isLeftSectionVisible }) {
  const map = useMap();

  useEffect(() => {
    const customControl = L.Control.extend({
      options: {
        position: 'topleft',
      },
      onAdd: function () {
        const classNameButton = isLeftSectionVisible
          ? 'leaflet-control leaflet-control-custom my-custom-button'
          : 'leaflet-control leaflet-control-custom my-custom-button-active';
        const container = L.DomUtil.create('button', classNameButton);
        container.onclick = function () {
          toggleRoleSVisible();
        };

        // Prevent map clicks when clicking the custom control
        L.DomEvent.disableClickPropagation(container);

        return container;
      },
    });

    const controlInstance = new customControl();
    map.addControl(controlInstance);

    // Cleanup control on component unmount
    return () => {
      map.removeControl(controlInstance);
    };
  }, [map, isLeftSectionVisible, toggleRoleSVisible]);

  return null;
}

const LocationMarker = ({ onLocationClick, selectedCoordinatesShowPage, setUnauthorized, setUnauthorizedShow }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [tempLocation, setTempLocation] = useState(null);
  const [address, setAddress] = useState(''); // Nowy stan na adres
  const markerRef = useRef(null);
  const { fetchWithAuth, token } = useAuthFetch();
  const { i18n, t } = useTranslation();

  const map = useMap();
  const [cookies, setCookie] = useCookies(['userID']);

  const locationIcon = new Icon({
    iconUrl: '/icons/gps.png',
    iconSize: [40, 40],
  });

  useMapEvents({
    click: async (e) => {
      if (selectedCoordinatesShowPage[0] === undefined) {
        onLocationClick(e.latlng);
      } else {
        setTempLocation(e.latlng);
        setShowConfirmation(true);

        const requestBody = {
          lat: e.latlng.lat,
          lng: e.latlng.lng,
        };

        // Pobieranie adresu z API na podstawie klikniętej lokalizacji
        try {
          const data = await AddressByLatLng(api.APP_URL_USER_API, requestBody, cookies.token, fetchWithAuth);
          if (data.error === 'Unauthorized') {
            console.error('Error getting address from coordinates:', data.error);
            setAddress('You are not logged in to see the new address'); // Ustaw adres w stanie
            setUnauthorized(true);
            setUnauthorizedShow(true);
            //onLocationClick(e.latlng);
          } else {
            if (Array.isArray(data)) {
              setAddress(data[0].fullAddress); // Ustaw adres w stanie
            } else {
              setAddress(data.fullAddress); // Ustaw adres w stanie
            }
          }
          

        } catch (error) {
          console.error('Error getting address from coordinates:', error);
          setAddress('There is no address'); // Ustaw adres w stanie
        }

        // Otwórz popup natychmiast
        if (markerRef.current) {
          markerRef.current.openPopup();
        }
      }
    },
  });

  const handleConfirm = (e) => {
    L.DomEvent.stopPropagation(e); // Prevent event propagation
    onLocationClick(tempLocation);
    setShowConfirmation(false);
    setTempLocation(null);
  };

  const handleCancel = (e) => {
    L.DomEvent.stopPropagation(e); // Prevent event propagation
    setShowConfirmation(false);
    setTempLocation(null);
  };

  return (
    <>
      {tempLocation && (
        <Marker position={tempLocation} icon={locationIcon} ref={markerRef}>
          {showConfirmation && (
            <Popup position={tempLocation} open={true}>
              <div>
                {(address !== 'There is no address' && address !== 'You are not logged in to see the new address') && (
                <p>{t("Do you want to change the address to this location?")}</p>
                )}
                <p>{address !== 'There is no address' && address !== 'You are not logged in to see the new address' ? `${t('Address')}: ${address}` : address === 'There is no address' ? t('Address: There is no address') : `${t(address)}`}</p> {/* Wyświetlenie adresu */}
                {(address !== 'There is no address' && address !== 'You are not logged in to see the new address') && (
                  <>
                    <button onClick={handleConfirm} className="popup-button popup-button-confirm">
                      {t("Yes")}
                    </button>
                    <button onClick={handleCancel} className="popup-button popup-button-cancel">
                      {t("No")}
                    </button>
                  </>
                )}
              </div>
            </Popup>
          )}
        </Marker>
      )}
    </>
  );
};

function Map({
  selectedCoordinatesShowPage,
  flyToLocation,
  toggleRoleSVisible,
  isLeftSectionVisible,
  isSmallScreen,
  geojson,
  setSelectedCoordinatesShowPage,
  onLocationClickHome,
  setUnauthorized,
  setUnauthorizedShow,
}) {
  const { t } = useTranslation();
  const locationIcon = new Icon({
    iconUrl: '/icons/gps.png',
    iconSize: [50, 50],
  });
  logger.log('selectedCoordinatesShowPage', selectedCoordinatesShowPage)

  const defaultIcon = new Icon({
    iconUrl: '/icons/gps.png', // Replace with the actual path to your default icon
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  const handleLocationClick = (latlng) => {
    console.log('Location clicked:', latlng);
    setSelectedCoordinatesShowPage(latlng);
    onLocationClickHome(latlng);
  };

  logger.log('geojson', geojson);
  return (
    <MapContainer
      center={
        selectedCoordinatesShowPage[0] !== undefined 
          ? [selectedCoordinatesShowPage[0], selectedCoordinatesShowPage[1]] 
          : [54.3520, 18.6466]
      }
      zoom={17}
      scrollWheelZoom={true}
      zoomControl={false}
    >
      <TileLayer
        attribution="Jawg.Dark"
        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
      />
      {(selectedCoordinatesShowPage[0] !== undefined) && (
        <Marker
          className="markerContainer-location"
          zIndexOffset={200}
          position={[selectedCoordinatesShowPage[0] + 0.00008, selectedCoordinatesShowPage[1] + 0.00008]}
          icon={locationIcon}
        >
          <Popup>
            <div>
              <strong>{t("Your address")}</strong>
            </div>
          </Popup>
        </Marker>
      )  
      }
      <LocationMarker onLocationClick={handleLocationClick} selectedCoordinatesShowPage={selectedCoordinatesShowPage} setUnauthorized={setUnauthorized} setUnauthorizedShow={setUnauthorizedShow}/>

      <FlyToMarkerReverse flyToLocation={selectedCoordinatesShowPage} />
      <FlyToMarker flyToLocation={flyToLocation} />

      {isSmallScreen !== undefined && (
        <CustomControl
          toggleRoleSVisible={toggleRoleSVisible}
          isLeftSectionVisible={isLeftSectionVisible}
        />
      )}
      <MarkerClusterGroup
        maxClusterRadius={30}
        spiderfyOnMaxZoom={true}

        showCoverageOnHover={true}
      >
      {geojson &&
        <GeoJSONMarkers geojson={geojson} />
      }
      </MarkerClusterGroup>
    </MapContainer>
  );
}

export default Map;

function FlyToMarker({ flyToLocation }) {
  const { map } = useLeafletContext();
  useEffect(() => {
    if (flyToLocation && flyToLocation[0] !== undefined) {
      const [lng, lat] = flyToLocation;
      map.flyTo([lat, lng], 16, {
        duration: 1.5,
      });
    }
  }, [flyToLocation, map]);

  return null;
}

function FlyToMarkerReverse({ flyToLocation }) {
  const { map } = useLeafletContext();
  useEffect(() => {
    if (flyToLocation[0] !== undefined) {
      const [lat, lng] = flyToLocation;
      let zoomLevel = 14;
      if (window.innerWidth < 800) {
        zoomLevel = 12;
      }
      map.flyTo([lat, lng], zoomLevel, {
        duration: 0.7,
      });
    }
  }, [flyToLocation, map]);

  return null;
}