import React, { useState, useRef, useEffect, use } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/ShowDataPage.css';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { SlMenu } from 'react-icons/sl';
import Map from './Map';
import Footer from './Footer';
import { SearchBar } from './SearchBar';
import { SearchResultsList } from './SearchResultsList';
import ShowDataButton from './ShowDataButton';
import UnauthorizedWithoutCookie from './UnauthorizedWithoutCookie';
import SubscryptionLimitReached from './SubscryptionLimitReached';
import Roles from './Roles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { motion, AnimatePresence } from 'framer-motion';
import { RightSectionSlide, MatchSectionSlide } from './anim.js';
import { logger } from '../logger';
import api from '../config';
import { useCookies } from 'react-cookie';
import CompareWindow from './CompareWindow';
import md5 from 'md5';
import { set } from 'animejs';
import { loadDataFetch, saveDataToApi, useAuthFetch, ReportFetch, AddressByLatLng, ReportSharingFetch } from './api.jsx';
import { a } from 'react-spring';
import { useSearchParams } from 'react-router-dom';
import TestingPopUp from './TestingPopUp.jsx';
import SubscryptionPopUp from './SubscryptionPopUp.jsx';

function ShowDataPage() {
  const navigate = useNavigate();
  const [isMatchVisible, setIsMatchVisible] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isMatchDetailsVisible, setisMatchDetailsVisible] = useState(false);
  const [footerKey, setFooterKey] = useState(0);
  const [cookies, setCookie] = useCookies(['userID']);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [reportId, setReportId] = useState(searchParams.get('report_id'));
  const [shareData, setShareData] = useState(searchParams.get('shareData'));
  const [signature, setSignature] = useState(searchParams.get('signature'));

  logger.log(reportId)
  logger.log(shareData)
  logger.log(signature)

  const [isReportIdChange, setIsReportIdChange] = useState(false);
  logger.log(location.state?.places)
  const placeHome = location.state?.places || {};
  const [places, setPlaces] = useState(placeHome);
  const[unauthorized, setUnauthorized] = useState(false);
  const[unauthorizedShow, setUnauthorizedShow] = useState(false);
  const [subscryptionLimitReached, setSubscryptionLimitReached] = useState(false);
  const [popUpType, setPopUpType] = useState('');
  logger.log(places)

  const address = location.state?.address || '';
  const [addressId, setAddressId] = useState(location.state?.addressId || '');
  const [geojson, setGeojson] = useState(location.state?.geojson || {});
  const addresses_home = location.state?.addresses || [];
  const selectedPreferences = location.state?.selectedPreferences || [];
  const preferencesSearchData = location.state?.preferencesSearchData || [];
  const selectedCoordinates = [
    location.state?.places.start_point.location.lat,
    location.state?.places.start_point.location.lon,
  ];
  logger.log("selectedCooselectedCoordinates", selectedCoordinates)
  const [addresses, setAddresses] = useState(addresses_home);
  const [mapLoading, setMapLoading] = useState(false);

  const [results, setResults] = useState([]);
  const [input, setInput] = useState(address);
  const [isResultClicked, setIsResultClicked] = useState(true);
  const [selectedCoordinatesShowPage, setSelectedCoordinatesShowPage] =
    useState(selectedCoordinates);
  logger.log(selectedCoordinatesShowPage)
  const [selectedPreferencesShowPage, setSelectedPreferencesShowPage] =
    useState(selectedPreferences);
  const buttonRef = useRef(null);
  const MatchRef = useRef();
  const [preferencesData, setPreferencesData] = useState([]);
  const { fetchWithAuth, token } = useAuthFetch();
  const [flyToLocation, setFlyToLocation] = useState(null);

  const [isLeftSectionVisible, setIsLeftSectionVisible] = useState(false);
  const [mainCategoriesToShow, setMainCategoriesToShow] = useState(null);
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const [categoryVisibility, setCategoryVisibility] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [preferencesSearchDataShowPage, setPreferencesSearchDataShowPage] =
    useState(preferencesSearchData);
  const userId = cookies.userID;
  logger.log(preferencesSearchDataShowPage)
  const [alarm, setAlarm] = useState('');

  const [isCompareWindowOpen, setIsCompareWindowOpen] = useState(false);


  // Funkcja fetch, która pobiera dane z serwera
  const fetchData = async () => {
    try {

      if (signature === undefined || shareData === undefined) {
        return;
      }
      const requestBody = {
        shareData: shareData,
        signature: signature,
      };
      logger.log('Request body:', requestBody);
      let id = await ReportSharingFetch(api.APP_URL_USER_API, requestBody, fetchWithAuth);
      logger.log('Report ID:', id);
      
      let data = await ReportFetch(id, api.APP_URL_USER_API, fetchWithAuth);
      const task_id = data.task_id;
      while (true) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        if (data.status === 'Pending' && data.task_id !== "undefined") {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          data = await ReportFetch(task_id, api.APP_URL_USER_API, fetchWithAuth);
        }
        if (data.task_id === "undefined") {
          return;
        }
        if (data.status === "Success") {
          return data;
        }

      }
    } catch (error) {
      console.error('Error getting report:', error);
    }
  };

  useEffect(() => {
    const fetchDataIfNeeded = async () => {
      setFooterKey(prevKey => prevKey + 1); 
      if(signature !== null && shareData !== null) {
        setIsLeftSectionVisible(true);
      }
      if (location.state) {
        setPlaces(placeHome);
        logger.log(placeHome, location.state)
        setGeojson(location.state?.geojson || {});
        setSelectedCoordinatesShowPage(selectedCoordinates);
      }

      if (selectedCoordinatesShowPage[0] === undefined && selectedCoordinatesShowPage[1] === undefined && !isReportIdChange) {
        logger.log(selectedPreferences.length, places);
        
        try {
          setMapLoading(true);
          const data = await fetchData();  // Wywołanie API
          setMapLoading(false);
          logger.log(data);
          if (data) {
            setInput(data.result.full.start_point.address.full_address);
            setAddressId(data.result.full.request.address_id);
            logger.log(data.result.full.request.address_id)
            setPlaces(data.result.full);
            setGeojson(data.result.geojson);
            setSelectedCoordinatesShowPage([data.result.full.start_point.location.lat, data.result.full.start_point.location.lon]);
            let custom_names = data.result.full.pois
            ? Object.entries(data.result.full.pois).flatMap(([category, subcategories]) => {
                return Object.entries(subcategories).flatMap(([subcategory, items]) => {
                  return {
                    name: subcategory,  // Assuming `item` has `name` or defaulting to subcategory name
                  }
                });
              })
            : [];
            logger.log(custom_names);
            setSelectedPreferencesShowPage(custom_names);
            setIsReportIdChange(true);

          }
        } catch (error) {
          console.error('Error fetching data:', error);  // Obsługa błędów
        }
      }
    };
  
    fetchDataIfNeeded(); // Wywołanie wewnętrznej funkcji asynchronicznej
  
  }, [selectedPreferences.length, location.state]); // Zależności

  logger.log(selectedPreferencesShowPage)

  const handleCompareWindowOpen = () => {
    setIsCompareWindowOpen(true);
  };

  useEffect(() => {
      //handleEnterPress();
  }, [selectedPreferencesShowPage]);

  const handleCompareWindowClose = () => {
    setIsCompareWindowOpen(false);
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 450);

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 450);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    logger.log('Cookies:', cookies);
    if (cookies.userID && !dataLoaded) {
      loadData(cookies.userID);
      setDataLoaded(true);
    } else {
      handleNewUserRegistration();
    }
  }, [dataLoaded]);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (MatchRef.current && !MatchRef.current.contains(event.target)) {
        setIsMatchVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [MatchRef]);

  const handleIsExpandedClick = () => {
    setIsExpanded(!isExpanded);
  };
  logger.log("Selected preferences:", selectedPreferencesShowPage)
  
  const handleNewUserRegistration = async () => {
    const userID = generateUserID();
    setCookie('userID', userID); // Set userID cookie
  };

  const generateUserID = () => {
    const timestamp = new Date().getTime();
    const randomNumber =
      Math.floor(Math.random() * (999999999 - 1000 + 1)) + 1000;
    const combinedString = timestamp.toString() + randomNumber.toString();
    const userID = md5(combinedString);
    return userID;
  };
  const handleUserReportClick = async () => {
    try {
      const id = generateUserID();
      saveData(id, address);
      let addressReport;
      if (results.length > 0) {
        addressReport = results[0].fullAddress;
      } else {
        addressReport = address;
      }

      const reportUrl = `/report?shareData=${shareData}&signature=${signature}&address=${encodeURIComponent(
        addressReport,
      )}`;
      window.open(reportUrl, '_blank');
    } catch (error) {
      console.error('Error getting report:', error);
      setUnauthorized(true);
      setUnauthorizedShow(true);
    }
  };

  const handlePreferencesData = (data) => {
    setPreferencesData(data);
  };

  const handleCategoryLabelClick = (categoryKey) => {
    setCategoryVisibility((prevVisibility) => ({
      ...prevVisibility,
      [categoryKey]: !prevVisibility[categoryKey],
    }));
  };

  const handleLanguageChange = (lng) => {
    setSelectedLanguage(lng);
    i18n.changeLanguage(lng);
    //window.location.reload();
  };

  const handleToggleLeftSection = () => {
    setIsLeftSectionVisible((prev) => !prev);
  };

  const handleDataCategoryClick = (address) => {
    /*
    let location = [];
    // Sprawdź, czy adres istnieje w places.custom_addressess
    const foundAddress = Object.values(places.custom_addressess).find(
      (addr) => addr.address.full === address,
    );
    if (foundAddress) {
      // Jeśli adres został znaleziony, pobierz jego lokalizację
      location = foundAddress.location;
      setFlyToLocation(location);
    } else {
      logger.warn(
        'Adres nie został znaleziony w places.custom_addressess lub ten adres jest twoim adresem wyszukiwania',
      );
    }
      */
  };

  let debounceTimeout = null; // Declare a global variable to store the timeout reference

  const handleEnterPress = () => {
    
    
    if (debounceTimeout) {
      clearTimeout(debounceTimeout); // Clear the existing timeout if the function is called again within the delay
    }
  
    debounceTimeout = setTimeout(() => {
      if (buttonRef.current) {
        logger.log('Enter press');
        buttonRef.current.click(); 
        setFooterKey(prevKey => prevKey + 1); 
      }
    }, 500); // Debounce time in milliseconds (adjust as necessary)
  };

  const loadData = async (id) => {

    try {
      const storedData = localStorage.getItem('myData');
      let request = {};
      if (storedData) {
        request = JSON.parse(storedData);
      }
      if (!request.APP_VERSION || request.APP_VERSION < api.APP_VERSION) {
        logger.log("REMOVE DATA");
        localStorage.removeItem('myData');
        return;
      }
      if (request && request.addresses && request.addresses) {
        setAddresses((request.addresses)) 
      }
      const i18nextLng = localStorage.getItem('i18nextLng');
      if (i18nextLng) {
        handleLanguageChange(i18nextLng)
      }
      /*
      if (request && request.transformedPreferences && request.transformedPreferences) {
        const names = request.transformedPreferences.map(item => item.category);
        const formattedNames = names.map(name => ({ name })); // Konwersja na obiekty { name: ... }
        
        setSelectedPreferencesShowPage(formattedNames);
      }
      */

    } catch (error) {
      console.error('Error getting report:', error);
    }
  };



  const saveData = async (id, searchBarAddress) => {
    logger.log('Save data:', dataLoaded);

    if (dataLoaded !== false) {
      try {
      logger.log('Save data:', id);

        let custom_names = [];
        let custom_addressess = [];
        const customNamesArray = [];
        if (preferencesSearchDataShowPage) {
          preferencesSearchDataShowPage.forEach((item) => {
            if (item.name) {
              custom_names.push(item);
            } else if (item.fullAddress) {
              custom_addressess.push(item);
            }
          });
        }
        custom_names.forEach((item) => {
          customNamesArray.push({
            name: item.name,
            main_category: item.category,
            category: item.sub_category,
          });
        });


        let address_report = results[0];

        const ids = transformedPreferences.map(item => item.id);

        const requestBody = {
          secret: id,
          address_report: address_report,
          language: i18n.language,
          addresses: addresses,
          results: results,
          categories: ids,
          transformedPreferences: transformedPreferences,
          requested_objects: custom_names,
          requested_addresses: custom_addressess,
          APP_VERSION: api.APP_VERSION
        };
        logger.log('Request body:', requestBody);
        localStorage.setItem('myData', JSON.stringify(requestBody));


      } catch (error) {
        console.error('Error getting report:', error);
      }
    }
  };

  const handleResultClick = (result) => {
    logger.log('Result clicked:', result);
    saveData(cookies.userID, '');
    setInput(result.fullAddress);
    setIsResultClicked(true);
    setAddresses([...addresses, result]);
    handleEnterPress();
  };

  const handleSearchBarChange = (value) => {
    setInput(value);
    setIsResultClicked(false);
  };

  const handleSearchBarChangeCompare = (value) => {
    setInput(value.fullAddress);
    setIsResultClicked(true);
    handleEnterPress();
  };

  const handleUserLocationUpdate = (address, lat, lng) => {
    setInput(`${address[0].address}`);
    setSelectedCoordinatesShowPage([lat, lng]);
    setIsResultClicked(true);
  };

  const handleToggleMatch = () => {
    setIsMatchVisible((prev) => !prev);
  };

  const handleToggleMatchDetails = () => {
    setisMatchDetailsVisible((prev) => !prev);
  };

  const handlePreferencesSelect = (preferences) => {
    setSelectedPreferencesShowPage(preferences);
    saveData(cookies.userID, '');
  };



  const handlePreferencesSearchSelect = (preferences) => {
    setPreferencesSearchDataShowPage(preferences);
  };

  const handleAddressesAdd = (newAddresses) => {
    setAddresses(newAddresses);
  };
  logger.log(selectedCoordinatesShowPage)

  const countVisibleCategories = () => {
    let custom_names = [];
    let custom_addressess = [];
    if (preferencesSearchDataShowPage) {
      preferencesSearchDataShowPage.forEach((item) => {
        if (item.name) {
          custom_names.push(item);
        } else if (item.fullAddress) {
          custom_addressess.push(item);
        }
      });
    }
    let totalPlacesCount = 0;
    let totalAddressesCount = 0;
    if (places.custom_pois) {
      Object.values(places.custom_pois).forEach((category) => {
        Object.values(category).forEach((preferences) => {
          totalPlacesCount += preferences.length;
        });
      });
    }
    if (places.custom_addressess) {
      Object.values(places.custom_addressess).forEach((address) => {
        if (
          address.route &&
          address.route.walking &&
          address.route.walking.distance < 3000
        ) {
          totalAddressesCount += 1;
        }
      });
    }

    if (
      categoriesToShow.length === 0 &&
      (preferencesSearchDataShowPage.length != 0 ||
        custom_addressess.length != 0)
    ) {

      const percentage =
        ((totalPlacesCount + totalAddressesCount) /
          (custom_names.length + custom_addressess.length)) *
        100;
      if (percentage > 100) {
        return {
          text: '100%',
          class: 'red-text',
          percentage: 100,
        };
      }
      if (isNaN(percentage) || percentage < 0) {
        return {
          text: '0%',
          class: 'red-text',
          percentage: 0,
        };
      }
      return {
        text: `${percentage.toFixed(0)}%`,
        class: 'red-text',
        percentage: percentage,
      };
    }

    if (places.pois === undefined) {
      return {
        text: '0%',
        class: 'red-text',
        percentage: 0,
      };
    }

    const visibleCategories = categoriesToShow.filter((category) => {
      return Object.keys(places.pois).some((interestKey) => {
        const interests = places.pois[interestKey];
        return (
          Array.isArray(interests[category.key]) &&
          interests[category.key].length > 0
        );
      });
    });

    const percentage =
      ((visibleCategories.length + totalPlacesCount + totalAddressesCount) /
        (categoriesToShow.length +
          custom_names.length +
          custom_addressess.length)) *
      100;
    // Ustal klasę tekstu w zależności od procentu
    let textClass = '';
    if (percentage <= 30) {
      textClass = 'red-text';
    } else if (percentage > 30 && percentage < 50) {
      textClass = 'orange-text';
    } else if (percentage > 50 && percentage < 70) {
      textClass = 'yellow-text';
    } else if (percentage > 70 && percentage < 90) {
      textClass = 'light-green-text';
    } else if (percentage > 90) {
      textClass = 'green-text';
    }
    if (percentage > 100) {
      return {
        text: '100%',
        class: 'red-text',
        percentage: 100,
      };
    }
    if (isNaN(percentage) || percentage < 0) {
      return {
        text: '0%',
        class: 'red-text',
        percentage: 0,
      };
    }
    return {
      text: `${percentage.toFixed(0)}%`,
      class: textClass,
      percentage: percentage,
    };
  };
  logger.log("is report id change", isReportIdChange)
  const handleReportId = (shareData, signature) => {
    setShareData(shareData);
    setSignature(signature);
    setIsReportIdChange(true);
    window.history.replaceState({}, '', `/show-addresses?shareData=${shareData}&signature=${signature}`);
    
  };

  useEffect(() => {
    if (places.pois) {
      const categories = Object.keys(places.pois);
      setMainCategoriesToShow(categories);  // Aktualizacja stanu
    } else {
      setMainCategoriesToShow(null);  // Jeśli brak danych, ustaw na null
    }
  }, [places]);
  logger.log(places)



  const filteredPreferencesData = Object.keys(preferencesData).reduce(
    (acc, key) => {
      // Filtruj preferencje w danej kategorii
      const filteredPreferences = preferencesData[key].filter((preference) => {
        return selectedPreferencesShowPage.some(
          (selectedPreference) => selectedPreference.name === preference.name,
        );
      });

      // Jeśli istnieją jakieś pasujące preferencje, dodaj je do wynikowej tablicy
      if (filteredPreferences.length > 0) {
        acc[key] = filteredPreferences;
      }

      return acc;
    },
    {},
  );


  const calculatePercentageInCategory = (category) => {
    try {
      const placesCounts = {};

      if (places.custom_pois) {
        Object.keys(places.custom_pois).forEach((category) => {
          placesCounts[category] = Object.values(
            places.custom_pois[category],
          ).reduce((total, preferences) => {
            return total + preferences.length;
          }, 0);
        });
      }
  
      const placesCategoryCount = placesCounts[category] || 0;
  
      let custom_names = [];
      if (preferencesSearchDataShowPage) {
        preferencesSearchDataShowPage.forEach((item) => {
          if (item.name) {
            custom_names.push(item);
          }
        });
      }
      const preferencesCategory = custom_names.filter(
        (item) => item.main_category === category,
      );
  
      const allPreferencesInCategory = places.pois[category];
  
      const filteredPreferencesInCategory = filteredPreferencesData[category];
  
      const categories = Object.keys(allPreferencesInCategory).filter(
        (key) =>
          Array.isArray(allPreferencesInCategory[key]) &&
          allPreferencesInCategory[key].length > 0,
      );
  
      const numberOfCategories = categories.length;
  
      const filteredPreferencesCount = filteredPreferencesInCategory
        ? filteredPreferencesInCategory.length
        : 0;
  
      const percentage =
        ((numberOfCategories + placesCategoryCount) /
          (filteredPreferencesCount + preferencesCategory.length)) *
        100;
  
      if (percentage > 100) {
        return '100%';
      } else if (filteredPreferencesCount > 0) {
        const percentage = (numberOfCategories / filteredPreferencesCount) * 100;
        return `${percentage.toFixed(0)}%`;
      } else {
        return '0%';
      }
    } catch {
      console.error('Error calculating percentage in category');
    }

  };

  const categoriesToShow = selectedPreferencesShowPage.map((preference) => {
    return {
      key: preference.name,
      label: preference.name,
    };
  });
  const transformedPreferences = Object.entries(filteredPreferencesData).reduce(
    (acc, [mainCategory, subCategories]) => {
      subCategories.forEach((subCategory) => {
        acc.push({ main_category: mainCategory, category: subCategory.name, id: subCategory.id });
      });
      return acc;
    },
    [],
  );

  const handleUnauthorizedWithoutCookieClose = () => {
    setUnauthorizedShow(false);
  };

  const saveDataToApi = async () => {
    saveData(cookies.userID, '');
  }

  /*
  categoriesToShow.sort((a, b) => {
    const hasPlacesA = !!places.pois[a.key];
    const hasPlacesB = !!places.pois[b.key];

    // Kategorie z miejscami będą na początku
    if (hasPlacesA && !hasPlacesB) {
      return -1;
    } else if (!hasPlacesA && hasPlacesB) {
      return 1;
    }
    return 0;
  });
*/


  const handleSubscryptionLimitReachedClose = () => {
    setUnauthorizedShow(false);
    setPopUpType('');

  };

  const handleSubscryptionLimitReachedCancel = () => {
    setPopUpType('');
  };

  const handleLocationClick = async (latlng) => {
    logger.log("cookies token", cookies.token)
    logger.log("subscryptionLimitReached", subscryptionLimitReached)
    if (subscryptionLimitReached === true || (unauthorized === true && cookies.token === undefined)) {
      setUnauthorizedShow(true);
    }
    logger.log('Location clicked:', latlng);
    const requestBody = {
      lat: latlng.lat,
      lng: latlng.lng,
    };
    try {
      const data = await AddressByLatLng(api.APP_URL_USER_API, requestBody, cookies.token, fetchWithAuth);
      if (data.error === 'Unauthorized') {
        setUnauthorized && setUnauthorized(true);
        return;
      }
      if (Array.isArray(data)) {
      
        setInput(data[0].fullAddress);
        setAddressId(data[0].id);
      } else {
        setInput(data.fullAddress);
        setAddressId(data.id);
      }
      setIsResultClicked(true);
      setIsReportIdChange(true);
      handleEnterPress();
      saveData(cookies.userID, '');

    } catch (error) {
      console.error('Error getting address from coordinates:', error);
    }

  }
  logger.log("unauthorizedShow", unauthorizedShow)
  return (
    <div className="ShowData">
      {(subscryptionLimitReached && unauthorizedShow && popUpType==='subscryption') && (
        <SubscryptionPopUp 
          onClose={handleSubscryptionLimitReachedCancel}
        />
      )}
      {(subscryptionLimitReached && unauthorizedShow && popUpType==='testing') && (
        <TestingPopUp 
          onClose={handleSubscryptionLimitReachedCancel}
        />
      )}
      {(subscryptionLimitReached && unauthorizedShow && popUpType==='') && (
        <SubscryptionLimitReached 
          onClose={handleSubscryptionLimitReachedClose}
          setPopUpType={setPopUpType}
        />
      )}
      {(unauthorized && unauthorizedShow) && (
        <UnauthorizedWithoutCookie
          onClose={handleUnauthorizedWithoutCookieClose}
        />
      )}
      <CompareWindow
        isOpen={isCompareWindowOpen}
        onClose={handleCompareWindowClose}
        setResults={setResults}
        showDataRef={buttonRef}
        inputShowAddress={input}
        setInputShowData={handleSearchBarChangeCompare}
        setIsResultClicked={setIsResultClicked}
        onEnterPress={handleEnterPress}
        ShowDataButtonCompare={'compare'}
        addressInput={address}
        addressesShowData={addresses}
        handleCompareWindowOpen={handleCompareWindowOpen}
        selectedPreferences={selectedPreferencesShowPage}
        transformedPreferences={transformedPreferences}
        preferencesSearchData={preferencesSearchDataShowPage}
        setAddressesShowPage={handleAddressesAdd}
        onSaveDataToApi={saveDataToApi}
        setUnauthorizedShow={setUnauthorizedShow}
      />
      <div className="showDataContainer">
        <div className="ShowDataPage">
          <div className="search-bar-container-show-data">
            <div>
              <button className="logo-show-data" title={t('Search Page')}>
                <img
                  src={'/images/15min_logo.svg'}
                  alt="Red Cross"
                  className="centered-img-cross"
                  onClick={() => window.location.href = "/show-addresses"}
                />
              </button>
            </div>
            <div>
              <div
                className="compare-button-show-data-page"
                onClick={handleCompareWindowOpen}
              >
                <div className="compare-button-show-data-page-text">
                  {t('Compare addresses')}
                </div>
                <div className="compare-button-show-data-page-icon">
                  <Icon
                    icon="material-symbols-light:balance"
                    id="compare-icon-button"
                  />
                </div>
              </div>
            </div>
            <div className="widthReportSection" ref={MatchRef}>
              <div className="position">
                <button
                  className={
                    isMatchVisible
                      ? 'toggleReportSectionisMatchVisibleClass'
                      : 'toggleReportSection'
                  }
                  title={t('Search Page')}
                  onClick={handleToggleMatch}
                >
                  <label
                    className={
                      isMatchVisible
                        ? 'toggleReportSectionLabelisMatchVisibleClass'
                        : 'toggleReportSection-label'
                    }
                  >
                    <div>
                      {t('Matching')} {countVisibleCategories().text}
                    </div>
                  </label>
                  {isMatchVisible ? (
                    <IoIosArrowUp className="toggleReportSection-icon" />
                  ) : (
                    <IoIosArrowDown className="toggleReportSection-icon" />
                  )}
                </button>
                {isMatchVisible && (
                  <motion.div
                    variants={MatchSectionSlide}
                    animate="enter"
                    initial="initial"
                  >
                    <div className="matchVidible">
                      <div className="show-data-hr-place-top">
                        <hr className="show-data-search-place-hr" />
                      </div>
                      <div className="">
                        {selectedPreferencesShowPage.length === 0 &&
                        preferencesSearchDataShowPage.length === 0 ? (
                          <label
                            className="selectyourCriteriaWithoutCategories"
                            style={{ paddingBottom: '9vh' }}
                          >
                            {t(
                              'Select your criteria in the menu on the left to see a match',
                            )}
                          </label>
                        ) : preferencesSearchDataShowPage.length != 0 &&
                          selectedPreferencesShowPage.length === 0 ? (
                          <div className="matchShadow">
                            <div>
                              <div className="show-data-hr-place">
                                <hr className="show-data-search-place-hr" />
                              </div>
                              <div
                                className="toggle-match-details-div"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <div
                                  className="toggle-match-details"
                                  onClick={() => handleUserReportClick()}
                                >
                                  {t('See full report')}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="matchShadow">
                            <div>
                              <div className="maxCriteriaLength">
                                {mainCategoriesToShow &&
                                  mainCategoriesToShow.map(
                                    (category, index) => (
                                      <div
                                        key={index}
                                        className="selectyourCriteria"
                                      >
                                        <div className="matchingName">
                                          {t(category) + ':'}{' '}
                                          {calculatePercentageInCategory(
                                            category,
                                          )}{' '}
                                        </div>
                                        <div className="matchContainer">
                                          <div className="matchBackground"></div>
                                          <div
                                            className="matchReactangle"
                                            style={{
                                              width: `calc(${calculatePercentageInCategory(
                                                category,
                                              )})`,
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    ),
                                  )}
                              </div>
                              <div className="show-data-hr-place">
                                <hr className="show-data-search-place-hr" />
                              </div>
                              <div
                                className="toggle-match-details-div"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <div
                                  className="toggle-match-details"
                                  onClick={() => handleUserReportClick()}
                                >
                                  {t('See full report')}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </motion.div>
                )}
              </div>
            </div>
          </div>
          {isSmallScreen ? (
            <div className="show-data-map-responsiveness">
              <div className="right-section-responsiveness map-container">
                <div className="column-show-data search-bar-and-results-show-data results-container-show-data">
                  <motion.div
                    variants={RightSectionSlide}
                    animate="enter"
                    exit="exit"
                    initial="initial"
                  >
                    <SearchBar
                      setResults={setResults}
                      showDataRef={buttonRef}
                      input={input}
                      setInput={handleSearchBarChange}
                      setIsResultClicked={setIsResultClicked}
                      onEnterPress={handleEnterPress}
                      searchBarClassName={
                        results && results.length > 0 && !isResultClicked
                          ? 'border-bottom show-data-page-search-bar'
                          : 'show-data-page-search-bar'
                      }
                      ShowDataButtonCompare={''}
                      handleCompareWindowOpen={handleCompareWindowOpen}
                      selectedPreferences={selectedPreferencesShowPage}
                      transformedPreferences={transformedPreferences}
                      preferencesSearchData={preferencesSearchDataShowPage}
                      setAlarm={setAlarm}
                      IconVisibility={true}
                      results={results}
                      setMapLoading={setMapLoading}
                      onReportId={handleReportId}
                      signature={signature}
                      shareData={shareData}
                      isReportIdChange={isReportIdChange}
                      setUnauthorized={setUnauthorized}
                      setUnauthorizedShow={setUnauthorizedShow}
                      addressId={addressId}
                    />
                  </motion.div>
                  {results && results.length > 0 && !isResultClicked && (
                    <SearchResultsList
                      results={results}
                      onResultClick={handleResultClick}
                      searchResultsListClassName="show-data-page-search-result-list"
                      searchResultsClassName="show-data-page-search-list"
                    />
                  )}
                </div>
                {mapLoading ? (
                  <div className="compare-main-div">
                    <div className="loader"></div>
                  </div>
                ) : (
                <Map
                  places={places.pois}
                  mainCategoriesToShow={mainCategoriesToShow}
                  categoriesToShow={categoriesToShow.map(
                    (category) => category.key,
                  )}
                  selectedCoordinatesShowPage={selectedCoordinatesShowPage}
                  flyToLocation={flyToLocation}
                  custom_names={places.custom_pois}
                  custom_addressess={places.custom_addressess}
                  preferencesSearchDataShowPage={preferencesSearchDataShowPage}
                  geojson={geojson}
                  setSelectedCoordinatesShowPage={setSelectedCoordinatesShowPage}
                  onLocationClickHome={handleLocationClick}
                  setUnauthorized={setUnauthorized}
                  setUnauthorizedShow={setUnauthorizedShow}
                />
                )}
              </div>
              <div className="left-section-responsiveness">
                {isExpanded == false ? (
                  <div
                    className="choose-criteria-mobile-div"
                    onClick={() => handleIsExpandedClick()}
                  >
                    <div className="choose-criteria-mobile">
                      {t('Select criteria')}
                    </div>
                  </div>
                ) : (
                  <div 
                    className="modal-overlay-category" 
                    onClick={handleIsExpandedClick}
                  >
                    <div 
                      className="modal-contents-category"  
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Roles
                        onSelectPreferences={handlePreferencesSelect}
                        selectedPreferencesShowPage={
                          selectedPreferencesShowPage
                        }
                        toggleRoleSVisible={handleToggleLeftSection}
                        isLeftSectionVisible={isLeftSectionVisible}
                        setPreferencedDataShowPage={handlePreferencesData}
                        preferencesSearchDataShowPage={
                          preferencesSearchDataShowPage
                        }
                        setPreferencesSearchDataShowPage={
                          handlePreferencesSearchSelect
                        }
                        handleSearch={handleEnterPress}
                        onAddressClick={handleDataCategoryClick}
                        isMobile={true}
                        toggleExpendedClick={handleIsExpandedClick}
                        setUnauthorizedShow={setUnauthorizedShow}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="show-data-map">
              <div className="left-section">
                <div>
                  <Roles
                    onSelectPreferences={handlePreferencesSelect}
                    selectedPreferencesShowPage={selectedPreferencesShowPage}
                    toggleRoleSVisible={handleToggleLeftSection}
                    isLeftSectionVisible={isLeftSectionVisible}
                    setPreferencedDataShowPage={handlePreferencesData}
                    preferencesSearchDataShowPage={
                      preferencesSearchDataShowPage
                    }
                    setPreferencesSearchDataShowPage={
                      handlePreferencesSearchSelect
                    }
                    handleSearch={handleEnterPress}
                    onAddressClick={handleDataCategoryClick}
                    isMobile={false}
                    toggleExpendedClick={handleIsExpandedClick}
                    setUnauthorizedShow={setUnauthorizedShow}
                  />
                </div>
              </div>

              <div
                className={`right-section map-container ${
                  isLeftSectionVisible ? '' : 'right-section-center'
                }`}
              >
                <div className="column-show-data search-bar-and-results-show-data results-container-show-data">
                  <motion.div
                    variants={RightSectionSlide}
                    animate="enter"
                    exit="exit"
                    initial="initial"
                  >
                    <SearchBar
                      setResults={setResults}
                      showDataRef={buttonRef}
                      input={input}
                      setInput={handleSearchBarChange}
                      setIsResultClicked={setIsResultClicked}
                      onEnterPress={handleEnterPress}
                      searchBarClassName={
                        results && results.length > 0 && !isResultClicked
                          ? 'border-bottom show-data-page-search-bar'
                          : 'show-data-page-search-bar'
                      }
                      ShowDataButtonCompare={''}
                      handleCompareWindowOpen={handleCompareWindowOpen}
                      selectedPreferences={selectedPreferencesShowPage}
                      transformedPreferences={transformedPreferences}
                      preferencesSearchData={preferencesSearchDataShowPage}
                      setAlarm={setAlarm}
                      IconVisibility={true}
                      results={results}
                      setMapLoading={setMapLoading}
                      onReportId={handleReportId}
                      signature={signature}
                      shareData={shareData}
                      isReportIdChange={isReportIdChange}
                      setUnauthorized={setUnauthorized}
                      setUnauthorizedShow={setUnauthorizedShow}
                      addressId={addressId}
                    />
                  </motion.div>
                  {results && results.length > 0 && !isResultClicked && (
                    <SearchResultsList
                      results={results}
                      onResultClick={handleResultClick}
                      searchResultsListClassName="show-data-page-search-result-list"
                      searchResultsClassName="show-data-page-search-list"
                    />
                  )}
                </div>
                {mapLoading ? (
                  <div className="compare-main-div">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <Map
                    places={places.pois}
                    mainCategoriesToShow={mainCategoriesToShow}
                    categoriesToShow={categoriesToShow.map(
                      (category) => category.key,
                    )}
                    selectedCoordinatesShowPage={selectedCoordinatesShowPage}
                    flyToLocation={flyToLocation}
                    custom_names={places.custom_pois}
                    custom_addressess={places.custom_addressess}
                    preferencesSearchDataShowPage={preferencesSearchDataShowPage}
                    isLeftSectionVisible={isLeftSectionVisible}
                    toggleRoleSVisible={handleToggleLeftSection}
                    isSmallScreen={isSmallScreen}
                    geojson={geojson}
                    setSelectedCoordinatesShowPage={setSelectedCoordinatesShowPage}
                    onLocationClickHome={handleLocationClick}
                    setUnauthorized={setUnauthorized}
                    setUnauthorizedShow={setUnauthorizedShow}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer useMargin={true} key={footerKey} setSubscryptionLimitReached={setSubscryptionLimitReached}/>
    </div>
  );
}

export default ShowDataPage;