import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import '../styles/SubscryptionLimitReached.css';



const SubscryptionLimitReached = ({ onClose, setPopUpType }) => {
  const { t } = useTranslation();

  const FREE_LIMIT = 3;
  const REQUEST_LIMIT = 50;
  const MONTHLY_COST = 20; 
  const ANNUAL_COST = 240; 
  const DISCOUNT = 0.25; 
  const AVAILABLE_SEATS = 10;

  const monthlyDiscounted = MONTHLY_COST * (1 - DISCOUNT);
  const annualDiscounted = ANNUAL_COST * (1 - DISCOUNT);


  const handleMonthlySubscriptionClick = () => {
    setPopUpType('subscryption');
  };

  const handleTestingClick = () => {
    setPopUpType('testing');
  };

  return (
    <div className="modal-overlay">
      <div className="modal-contents-subscryption">
        <div className="sub-comment">
          {t(
            `Your query limit has run out`
          )}
          <button className="close-button" onClick={onClose}>
            <Icon icon="material-symbols-light:close" id="close-icon-button" />
          </button>
        </div>
        <div className='progress-container-sub'>
          <div
            className='progress-bars'
            style={{ width: `100%` }}
          ></div>
        </div>
        <div className="sub-second-comment">
          {t('free_plan_message', { FREE_LIMIT })}
        </div>
        <div className="sybscryption-button-choose">
          <button className="subscryption-button" onClick={handleMonthlySubscriptionClick}>
            {t('Subscription')}
          </button>
          <button className="subscryption-button" onClick={handleTestingClick}>
            {t('Testing')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscryptionLimitReached;
