import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import { logger } from '../logger';
import {
    doctors,
    fast_food,
    bank,
    bar,
    cafe,
    restaurant,
    school,
    theatre,
    police,
    atm,
    car_wash,
    charging_station,
    clinic,
    dentist,
    hospital,
    kick_scooter_parking,
    kindergarten,
    library,
    payment_centre,
    pharmacy,
    post_box,
    post_office,
    recycling,
    shelter,
    university,
    veterinary,
    waste_disposal,
    biergarten,
    food_court,
    ice_cream,
    pub,
    college,
    dancing_school,
    driving_school,
    first_aid_school,
    language_school,
    surf_school,
    toy_library,
    research_institute,
    training,
    music_school,
    traffic_park,
    bicycle_parking,
    bicycle_repair_station,
    bicycle_rental,
    bicycle_wash,
    boat_rental,
    boat_sharing,
    bus_station,
    car_rental,
    car_sharing,
    compressed_air,
    vehicle_inspection,
    driver_training,
    ferry_terminal,
    fuel,
    grit_bin,
    motorcycle_parking,
    parking,
    parking_entrance,
    parking_space,
    taxi,
    weighbridge,
    payment_terminal,
    bureau_de_change,
    baby_hatch,
    nursing_home,
    social_facility,
    arts_centre,
    casino,
    cinema,
    community_centre,
    conference_centre,
    events_venue,
    exhibition_centre,
    fountain,
    music_venue,
    nightclub,
    planetarium,
    public_bookcase,
    social_centre,
    studio,
    courthouse,
    fire_station,
    post_depot,
    prison,
    ranger_station,
    townhall,
    bbq,
    bench,
    dog_toilet,
    dressing_room,
    drinking_water,
    give_box,
    mailroom,
    parcel_locker,
    shower,
    telephone,
    toilets,
    water_point,
    watering_place,
    sanitary_dump_station,
    waste_basket,
    waste_transfer_station,
    animal_boarding,
    animal_breeding,
    animal_shelter,
    animal_training,
    baking_oven,
    clock,
    crematorium,
    dive_centre,
    funeral_hall,
    grave_yard,
    hunting_stand,
    internet_cafe,
    kitchen,
    kneipp_water_cure,
    lounger,
    marketplace,
    monastery,
    photo_booth,
    place_of_mourning,
    place_of_worship,
    public_bath,
    public_building,
    refugee_site,
    vending_machine,
    universal_icon,
    Cukiernie_i_piekarnie,
    sushi,
    shop,
    playground,
    Kluby_bilardowe,
    Gry_Planszowe,
    fitness,
    meditacja,
    horseback_riding,
    shooting_range,
    depilation,
    barber_shop,
    hairdressers,
    nails,
    massage,
    model_making,
    three_d_printers,
    office,
    pizza,
    shoppingBag,
    butcher,
    cosmetics,
    shop_for_animal,
    entertainment_centers,
    cinema_theateres_operas,
    game_room,
    gym,
    mindfulness,
    swimming_pool,
    open_space,
    travel,
    healthcare,
    copy_machine,
    family_doctor,
    bike,
    station,
    trams_station,
} from './Icons';

const GeoJSONMarkers = ({ geojson }) => {
  const { t } = useTranslation();

  const iconMapping = {
    'Smażalnia ryb': fast_food,
    'Puby i bary': bar,
    'Pubs and bars': bar,
    'Street Food': food_court,
    'Małe skłepy spożywcze': shoppingBag,
    'Small grocery stores': shoppingBag,
    'Shopping centers': shop,
    Hipermarkety: shop,
    Supermarkety: shop,
    'Sklepy mięsne': butcher,
    Zdrowie: pharmacy,
    Kosmetyczne: cosmetics,
    Cosmetics: cosmetics,
    Księgarnie: library,
    Zoologiczne: shop_for_animal,
    'Centra rozrywki': entertainment_centers,
    'Entertainment centers': entertainment_centers,
    'Centra handlowe': shop,
    'Kina, teatry, opery': cinema_theateres_operas,
    'Sale gier': game_room,
    'Kluby, dyskoteki': nightclub,
    Siłownie: gym,
    Mindfulness: mindfulness,
    'Baseny i kąpieliska': swimming_pool,
    'Open space': open_space,
    'Szkoły policealne': school,
    'Szkoły podstawowe': school,
    'Clubs, discos': nightclub,
    'Gyms': gym,
    Park: open_space,
    Sightseeing: travel,
    'Vocational schools': school,
    'Primary schools': school,
    'Secondary school': school,
    'Kindergartens': kindergarten,
    'Libraries': library,
    'Art and hobby schools': school,
    Spa: fitness,
    'Beauty salons': massage,
    'Hairdressers and barbers': hairdressers,
    'Family doctors': family_doctor,
    'Private clinics and cooperatives': clinic,
    'Internists': doctors,
    'Pediatricians': doctors,
    'Outpatient clinics': clinic,
    'Mevo bikes': bike,
    'Stops': station,
    Przedszkola: school,
    Żłobki: kindergarten,
    Biblioteki: library,
    Buses: bus_station,
    Trams: trams_station,
    'Szkoły artystyczne i hobbystyczne': school,
    'Placówki pocztowe, bankomaty': post_office,
    'SPA, masaże': massage,
    'Salony kosmetyczne': massage,
    'Fryzjerzy i barberzy': hairdressers,
    'Artykuły domowe i hobbystyczne': shop,
    Turystyka: travel,
    'Pielęgnacja zwerząt': healthcare,
    'punkty ksero': copy_machine,
    'Lekarze rodzinni': family_doctor,
    Interniści: doctors,
    Pediatrzy: doctors,
    'Prywatne kliniki i spółdzielnie': clinic,
    Apteki: pharmacy,
    Przychodnie: clinic,
    'Rowery MEVO': bike,
    Przystanki: station,
    Bistro: fast_food,
    'Food hall': food_court,
    Bary: bar,
    'Szkoły średnie': school,
    Winiarnie: bar,
    Browary: bar,
    'Cukiernie i piekarnie': Cukiernie_i_piekarnie,
    'MEVO bikes': bike,
    'Stops': station,
    Puby: pub,
    Restauracje: restaurant,
    'Bary mleczne': bar,
    Sushi: sushi,
    Pizzerie: pizza,
    Pizzerias: pizza,
    Kebaby: fast_food,
    'Sklepy rybne': shop,
    'Kawiarnie i herbaciarnie': cafe,
    'Bary przekąskowe': fast_food,
    'Palarnie kawy': cafe,
    Naleśnikarnie: fast_food,
    'Sklepy garmażeryjne': shop,
    'Sklepy ogólnospożywcze': shop,
    'Produkcja spożywcza': shop,
    'Odżywki i suplementy diety': shop,
    Pharmacies: pharmacy,
    Drogerie: shop,
    'Sklepy zielarsko-medyczne': shop,
    'Sklepy monopolowe': shop,
    Supermarkety: shop,
    Supermarkets: shop,
    'Sklepy z winami': shop,
    Perfumerie: shop,
    Księgarnie: shop,
    'Zdrowa żywność': shop,
    Hipermarkety: shop,
    'Punkty ksero': shop,
    Targowiska: shop,
    'Sklepy mięsne': shop,
    'Place zabaw i małpie gaje': playground,
    Kina: cinema,
    Cinemas: cinema,
    'Centra handlowe': shop,
    Kręgielnie: shop,
    'Kluby nocne': nightclub,
    'Kluby, dyskoteki': nightclub,
    'Kluby bilardowe': Kluby_bilardowe,
    'Gry planszowe': Gry_Planszowe,
    'Siłownie i fitness kluby': fitness,
    Aquafitness: fitness,
    'Gimnastyka artystyczna i sportowa': fitness,
    Medytacje: meditacja,
    'Jazda konna': horseback_riding,
    Squash: fitness,
    Strzelnice: shooting_range,
    Joga: meditacja,
    'Nauka pływania': fitness,
    'Windsurfing, kitesurfing': fitness,
    Baseny: fitness,
    'Studio Pilates': fitness,
    'Sztuki walki i samoobrona': fitness,
    Golf: fitness,
    'Korty badminton': fitness,
    Kajaki: fitness,
    'Kluby sportowe': fitness,
    'Nordic walking': fitness,
    Wspinaczka: fitness,
    Skateparki: fitness,
    Sauny: fitness,
    Lodowiska: fitness,
    'Punkty odbioru przesyłek': post_office,
    'Szkolnictwo policealne i pomaturalne': college,
    żłobki: kindergarten,
    Biblioteki: library,
    'Kursy muzyczne': music_school,
    Museum: library,
    'Nauka tenisa': training,
    'Szkoły podstawowe': school,
    Przedszkola: kindergarten,
    'Szkoły wyższe': university,
    'Uniwersytety Trzeciego Wieku': university,
    'Kursy tańca': dancing_school,
    'Szkolenia psów': training,
    'Szkoły wizażu i stylizacji': training,
    'Pole dance': dancing_school,
    'Kursy szybkiego czytania': training,
    'Szkoły językowe': language_school,
    'Szkółki Piłkarskie': training,
    Bankomaty: atm,
    'Bankomaty BPH': atm,
    'Bankomaty ING Bank Śląski': atm,
    'Bankomaty SGB': atm,
    'Bankomaty PKO BP': atm,
    'Bankomaty Citibank': atm,
    'Bankomaty eCard': atm,
    'Bankomaty Euronet': atm,
    Banki: bank,
    SPA: fitness,
    'Depilacja laserowa': depilation,
    'Depilacja pastą cukrową': depilation,
    'Barber Shop': barber_shop,
    Fryzjerzy: hairdressers,
    'Przedłużanie rzęs': hairdressers,
    Paznokcie: nails,
    Solaria: massage,
    'Salony masażu': massage,
    'Przedłużanie włosów': hairdressers,
    Masażyści: massage,
    'Depilacja woskiem': depilation,
    'Salony kosmetyczne': massage,
    Modelarstwo: model_making,
    'Drukarnie 3D': three_d_printers,
    Bookcrossing: library,
    'Pralnie, magiel - wyposażenie, artykuły': shop,
    Pralnie: shop,
    'Hotele dla zwierząt': animal_boarding,
    Weterynarze: veterinary,
    'Pielęgnacja zwierząt': animal_training,
    'Sklepy zoologiczne': animal_shelter,
    'Współdzielenie biura - coworking': office,
    'Lekarze rodzinni': doctors,
    Interniści: doctors,
    Pediatrzy: doctors,
    Apteki: pharmacy,
    'Prywatne kliniki i spółdzielnie': clinic,
    'Apteki całodobowe': pharmacy,
    'Przychodnie, poradnie': clinic,
    Bookstores: library,
    'Fast Food': fast_food,
    'Pet stores': shop_for_animal,
    doctors: doctors,
    bank: bank,
    bar: bar,
    cafe: cafe,
    'Cafes and Bakeries': cafe,
    'Clothing stores': shop,
    'Household and hobby items': shop,
    school: school,
    theatre: theatre,
    police: police,
    clinic: clinic,
    dentist: dentist,
    atm: atm,
    car_wash: car_wash,
    charging_station: charging_station,
    restaurant: restaurant,
    Restaurants: restaurant,
    park: open_space,
    bike: bike,
    pharmacy: pharmacy,
    universal_icon: universal_icon,
    'Convenience stores': shop,
  };

  const getIcon = (category) => {
    return iconMapping[category] || universal_icon;
  };

  const onEachFeature = (feature, layer) => {
    const { name } = feature.properties;
    if (name) {
      layer.bindPopup(name);
    }
  };

  return (
    <div>
      {geojson.features && geojson.features.map((feature, index) => {
        const { geometry, properties } = feature;
        const { coordinates } = geometry;
        const icon = getIcon(properties.category);
        const position = [coordinates[1], coordinates[0]];
        if (properties.name === 'Start Point') {
          return null;
        }

        return (
          <Marker key={index} position={position} icon={icon}>
            <Popup>
                <div>
                <strong>{properties.name}</strong>
                </div>
                <div>{properties.address.full_address}</div>
            </Popup>

          </Marker>
        );
      })}
    </div>
  );
};

export default GeoJSONMarkers;
