import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
const UnauthorizedWithoutCookie = ({onClose }) => {


    const { t } = useTranslation();

    const handleRegisterClick = () => {
        window.location.href = '/sign-in';
    }

    return (
    <div className="modal-overlay">
        <div className="modal-contents">
        <div className="first-comment" style={{marginTop: '15vh'}}>
            {t('Sorry, but unregistered users cannot explore the different addresses and filters')}
            <button className="close-button" onClick={onClose}>
            <Icon icon="material-symbols-light:close" id="close-icon-button" />
            </button>
        </div>
        <div className="first-comment">
            {t(
            'Please register or login here',
            )}
        </div>
        <div className='sign-up-button' style={{width:'auto'}} onClick={handleRegisterClick}>
            <div className='sign-up-button-label' >{t('Register or Log in')}</div>
        </div>
        </div>
    </div>
    );
    

    
}

export default UnauthorizedWithoutCookie;