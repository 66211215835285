import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from 'react-leaflet';
import { useLeafletContext } from '@react-leaflet/core';

import { Icon } from 'leaflet';
import '../styles/HomeMapClick.css';

const LocationMarker = ({ onLocationClick }) => {
  useMapEvents({
    click(e) {
      onLocationClick(e.latlng);
    },
  });
  return null;
};

function FlyToMarker({ flyToLocation, onLocationClick }) {
  const { map } = useLeafletContext();
  useEffect(() => {
    if (flyToLocation) {
      console.log('Fly to location:', flyToLocation);
      const lat = flyToLocation.lat;
      const lng = flyToLocation.lng;
      map.flyTo([lat, lng], 16, {
        duration: 1.5,
      });
      onLocationClick(flyToLocation);
    }
  }, [flyToLocation, map]);

  return null;
}

const HomeMapClick = ({ onLocationClickHome, location }) => {
  const [position, setPosition] = useState(null);

  const handleLocationClick = (latlng) => {
    console.log('Location clicked:', latlng);
    setPosition(latlng);
    onLocationClickHome(latlng);
  };

  const locationIcon = new Icon({
    iconUrl: '/icons/gps.png',
    iconSize: [35, 35],
  });

  return (
    <MapContainer center={[54.3520, 18.6466]} zoom={13} style={{ height: '100%', width: '100%', margin: 0 }}>
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
        attribution="Jawg.Dark"
      />
      <LocationMarker onLocationClick={handleLocationClick} />
      <FlyToMarker flyToLocation={location} onLocationClick={handleLocationClick}/>
      {position && (
        <Marker
          className="markerContainer-location"
          zIndexOffset={200}
          position={position}
          icon={locationIcon}
        />
      )}
    </MapContainer>
  );
};

export default HomeMapClick;