import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/ShowDataButton.css';
import { FaSearch } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import api from '../config';
import { Icon } from '@iconify/react';
import { logger } from '../logger';
import { ReportFetch, ReportIdFetch, useAuthFetch, ReportSharingFetch, AdressFetch } from './api.jsx';
import { get, set } from 'animejs';
import { useCookies } from 'react-cookie';


export const ShowDataButton = React.forwardRef(
  (
    {
      address,
      addresses,
      results,
      selectedPreferences,
      transformedPreferences,
      preferencesSearchData,
      ShowDataButtonCompare,
      setAlarm,
      IconVisibility,
      setMapLoading,
      addressId,
      onReportId,
      signature,
      shareData,
      isReportIdChange,
      setUnauthorized,
    },
    ref,
  ) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [cookies, setCookie] = useCookies(['token']);
    const [iconLoading, setIconLoading] = useState(false);

    const { fetchWithAuth, token } = useAuthFetch();
    const handleUserLocationClick = async () => {
      if (address == '') {
        if (ShowDataButtonCompare !== 'alert-none') {
          setAlarm(t('invalid address'));
        }
      } else {
        if (setMapLoading) {
          setMapLoading(true);
        } else {
          setIconLoading(true);
        }
        let report_id;
        if ((!signature || !shareData) || isReportIdChange) {
          report_id = await getReportId();

          if (report_id && report_id.error) {
            logger.error('Error getting report id:', report_id.error);
            if (report_id.error === 'Unauthorized') {
              setUnauthorized && setUnauthorized(true);
            }
            if (setMapLoading) setMapLoading(false);
            setIconLoading(false);
            return;
          }

          if (report_id === null || report_id === undefined) {
            logger.error('No report id found');
            if (setMapLoading) setMapLoading(false);
            setIconLoading(false);
            setAlarm(t('Server error'));
            return;
          }
        } else {
          const requestBody = {
            shareData: shareData,
            signature: signature,
          };
          logger.log('Request body:', requestBody);
          report_id = await ReportSharingFetch(api.APP_URL_USER_API, requestBody, fetchWithAuth);
        }
        const poi = await getplacesFromCoordinates(report_id);
        if (poi === undefined) {
          logger.error('No places found');
          if (setMapLoading) setMapLoading(false);
          setIconLoading(false);
          setAlarm(t('Server error'));
          return
        }
        const places = poi.result.full

        const shareDataSDB = poi.result.shareData
        logger.log('Share data:', shareDataSDB);
        const signatureSDB = poi.result.signature
        logger.log('Signature:', signatureSDB);
        onReportId(shareDataSDB, signatureSDB);

        logger.log('Places:', places);
        const geojson = poi.result.geojson
        if (setMapLoading) setMapLoading(false);
        setIconLoading(false);
        if (places === undefined) {
          logger.error('No places found');
          if (setMapLoading) setMapLoading(false);
          setIconLoading(false);
          setAlarm(t('invalid address'));
        } else {
          navigate(`/show-addresses?shareData=${shareDataSDB}&signature=${signatureSDB}`, {
            state: {
              address,
              addresses,
              places,
              geojson,
              selectedPreferences,
              preferencesSearchData,
            },
          });
          
          
        }
      }
    };

    const getReportId = async () => {
      try {
        let custom_names = [];
        let custom_addresses = [];
        if (preferencesSearchData) {
          preferencesSearchData.forEach((item) => {
            if (item.name) {
              custom_names.push(item.id);
            } else if (item.fullAddress) {
              custom_addresses.push(item.id);
            }
          });
        }
        logger.log('Request body ID:', address);
        logger.log('Request body ID:', addressId);

        const item = results && results.find((item) => item.fullAddress === address);
        let id;
        if (item === undefined && addressId) {
          id = addressId; 
        }
        logger.log("transformedPreferences", transformedPreferences);
        let ids = transformedPreferences && transformedPreferences.map(item => item.id);

        if (ids === undefined) {
          ids = [];
        } else if (ids.length === 0 || ids[0] === undefined) {
          ids = [];
        }

        const requestBody = {
          addressId: item ? item.id : id, 
          categoryIds: ids,
          customAddressIds: custom_addresses,
          customPlacesIds: custom_names,
          distance: 1000,
        };
        logger.log('Request body ID another:', requestBody);
        const data = await ReportIdFetch(requestBody, api.APP_URL_USER_API, cookies.token, fetchWithAuth);
        logger.error('Report ID:', data);
        return data;
      } catch (error) {
        console.error('Error getting report id:', error);
        return { error };
      }
    };

    const getplacesFromCoordinates = async (report_id) => {
      try {
        logger.log('Request body ID getplacesFromCoordinates:', report_id);
        if (report_id === undefined) {
          return;
        }

        let data = await ReportFetch(report_id, api.APP_URL_USER_API, fetchWithAuth);
        const task_id = data.task_id;
        while (true) {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          if (data.status === 'Pending' && data.task_id !== "undefined") {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            data = await ReportFetch(task_id, api.APP_URL_USER_API, fetchWithAuth);
          }
          if (data.task_id === "undefined") {
            return;
          }
          if (data.status === "Success") {
            logger.log('Report:', data);
            return data;
          }

        }
      } catch (error) {
        console.error('Error getting report:', error);
      }
    };
    return (
      <div>
        <button
          ref={ref}
          className="show-data-button-invisible"
          onClick={handleUserLocationClick}
        />
        {IconVisibility && (
        <button
          className="show-data-button"
          onClick={handleUserLocationClick}
          title={t('Show results')}
        >
          {iconLoading && <div class="show-data-button-loader"></div>}
          {!iconLoading && <Icon icon="carbon:search" id="search-icon-button" />}

        </button>
        )
        }

      </div>
    );
  },
);

export default ShowDataButton;